import React from 'react';
import { LayoutWithHeader } from '@components/index';
import strings from '@config/strings';
import { PromoCta } from '@goodfynd/react-web.content.promo-cta';
import { Container } from '@goodfynd/react-web.layout.container';
import Head from 'next/head';

import { setPageTitle } from '../utils/base';

function Home(): JSX.Element {
  return (
    <>
      <Head>
        <title>{setPageTitle('Home')}</title>
        <meta content="home page" name="description" />
      </Head>

      <Container
        css={{
          '&.MuiContainer-root': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: 32,
            flexGrow: 1,
          },
        }}
      >
        <PromoCta
          button_href="/dashboard"
          button_text="Get Started"
          image="/images/bg/home.svg"
          image_position="right"
          title={strings.titles.homeTitle}
          content="Streamline your operations, boost your sales, and delight your
          customers."
        />
      </Container>
    </>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <LayoutWithHeader>{page}</LayoutWithHeader>;
};

export default Home;
